<template>
  <header>
    <div class="avatar">
      <img  @click="showPreview" :src="userInfo.userImg" onerror="this.onerror=null;this.src='https://shijiaoke.oss-cn-beijing.aliyuncs.com/public/not_loaded.jpg'"  alt="" />

    </div>
    <div class="user-name">
      <p>{{ userInfo.nickName }}</p>
      <p>{{ userInfo.personalSignature }}</p>
    </div>
  </header>
  <main>
    <ul class="menu border-bottom">
      <li class="menu-li">
        <router-link :to="{ name: 'Order' }" class="nei-order">
          <img src="@/assets/img/mine/icon65.png" alt="" />
          全部订单
        </router-link>
      </li>
      <li class="menu-li">
        <router-link :to="{ name: 'Bill' }" class="user-account">
          <img src="@/assets/img/mine/zhangdan.png" alt="" />
          我的账单
        </router-link>
      </li>
      <li class="menu-li">
        <router-link :to="{ name: 'Invoice' }" class="user-account">
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/86164d31ae5d94aad226006fe381d349.png" alt="" />
          我的发票
        </router-link>
      </li>
      <li class="menu-li">
        <router-link :to="{ name: 'Intergral' }" class="user-account">
          <img src="@/assets/img/mine/jifen.png" alt="" />
          我的积分
        </router-link>
      </li>
    </ul>
    <ul class="menu menu-wapper">
      <li class="menu-li">
        <router-link
          :to="{
            name: 'Personal',
            query: { uid: userInfo.id, type: 'mine' },
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/c07cc886fc6867b81b611fce24ced053.png" alt="" />
          我的主页
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'UserInfo',
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/b587ff5b602e7dcc19221f1b64a5d27d.png" alt="" />
          我的资料
        </router-link>
      </li>
      <!--            <li class="menu-li">-->
      <!--                <a href="">-->
      <!--                    <img src="http://www.bncc.org.cn/static/m/images/icon69.png" alt="">-->
      <!--                    防疫打卡-->
      <!--                </a>-->
      <!--            </li>-->
      <!-- <li class="menu-li">
        <router-link
          :to="{
            name: 'FootPrint',
          }"
        >
          <img src="http://www.bncc.org.cn/static/m/images/zuji.png" alt="" />
          我的足迹
        </router-link>
      </li> -->
      <li class="menu-li">
        <router-link
          :to="{
            name: 'Message',
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/48ecd6c9a0f16e02a3eae797df8639be.png" alt="" />
          我的消息
        </router-link>
      </li>

      <li class="menu-li">
        <router-link
          :to="{
            name: 'Attention',
            query: {
              uid: userInfo.id,
            },
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/fd485bdbb6a7a5f746ddb333d147f29d.png" alt="" />
          我的关注
        </router-link>
      </li>

      <li class="menu-li">
        <router-link
          :to="{
            name: 'Unsubscribe',
               query: {
              uid: userInfo.id,
            },
          }"
        >
          <img src="@/assets/img/mine/icon8.png" alt="" />
          <!-- <img src="http://www.bncc.org.cn/static/m/images/icon8.png" alt="" /> -->
          我的粉丝
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
                :to="{
            name: 'AddressOfUser',
          }"
        >
          <img src="@/assets/img/mine/icon77.png" alt="" />
          我的地址
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'Collection',
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/fc726e42303efae99ecdf2d67129f38c.png" alt="" />
          <!-- <img src="http://www.bncc.org.cn/static/m/images/icon73.png" alt="" /> -->
          我的收藏
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'Account',
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/4eef569524aaf0e48551832b80785c23.png" alt="" />
          <!-- <img src="http://www.bncc.org.cn/static/m/images/icon78.png" alt="" /> -->
          账号管理
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'Prize',
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/b2e6ea0973be4ea7baca8d71afe7ae62.png" alt="" />
          <!-- <img src="http://www.bncc.org.cn/static/m/images/icon78.png" alt="" /> -->
          我的奖品
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'signIn',
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/db7451ffee3963e9eb817dcfb8c554ce.png" alt="" />
          我的任务
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'Suggest',
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/0c3ccecbc485027f80e11071f73e59fe.png" alt="" />
          建议投诉
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
          :to="{
            name: 'ShopCar',
          }"
        >
          <img src="@/assets/img/common/icon23-1.png" alt="" />
          购物车
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
            :to="{
            name: 'Coupon',
             query: { uid: userInfo.id, type: 'mine' }
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/2fcf11e29c374d0a5eda54588a70876e.png" alt="" />
          优惠券
        </router-link>
      </li>
      <li class="menu-li">
        <router-link
            :to="{
            name: 'myInvitation',
          }"
        >
          <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/e959acbaa3822b82cc7a91a66f703034.png" alt="" />
          我的邀请
        </router-link>
      </li>
<!--      <li class="menu-li">-->
<!--       <router-link-->
<!--         :to="{-->
<!--           name: 'Invitation',-->
<!--           query: { invitationCode: userInfo.id}-->
<!--         }"-->
<!--       >-->
<!--         <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/c9cac8f2b489dbb908caee5ada712429.png" alt="" />-->
<!--         邀请注册-->
<!--       </router-link>-->
<!--     </li>-->

      <!--            <li class="menu-li">-->
      <!--                <a href="">-->
      <!--                    <img src="http://www.bncc.org.cn/static/m/images/icon69.png" alt="">-->
      <!--                    订餐预约-->
      <!--                </a>-->
      <!--            </li>-->
    </ul>
    <preview
      v-if="userInfo && userInfo.userImg && previewing"
      @close="closePreview"
      :imgList="[{ src: userInfo.userImg }]"
    ></preview>
  </main>
  <fixed-footer></fixed-footer>
</template>


<script lang="ts">
import { defineComponent, inject, ref } from "vue";

import fixedFooter from "@/components/common/Footer.vue";

import axios from "@/api/axios";

import Toast from "@/components/UI/Toast";
import Preview from "@/components/UI/Preview";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import Modal from "@/components/UI/Modal";
import { wechatLoginMine } from "@/api/wechatLogin";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "Mine",
  components: {
    fixedFooter,
    Preview,
  },
  setup() {
    //  展示用户信息
    const userInfo = inject("userInfo") as any;
    const route = useRoute();
    const UPDATE_USERINFO = inject("UPDATE_USERINFO") as (data: object) => void;
    function handleUserInfo(data: object) {
      UPDATE_USERINFO(data);
    }
    function updateToken(token: string) {
      localStorage.setItem("token", token);
    }
    wechatLoginMine(route.query.code as string, route.query.state as string).then((res: any) => {
      if (typeof res === "object" && res.data.success) {
        updateToken(res.data.token);
        handleUserInfo(res.data.obj);
      }
    })
    onBeforeRouteLeave((to, from) => {
      if (new Date().getHours() < 8 || new Date().getHours() >= 23) {
        if (
          to.name == "Order" ||
          to.name == "Invoice" ||
          to.name == "Bill" ||
          to.name == "Intergral"
        ) {
          Modal({
            title: "温馨提醒",
            content: "23:00-8:00期间此功能正在维护中...",
          });
          return false;
        }
      }
    });
    //  预览大图
    const previewing = ref(false);
    function closePreview() {
      previewing.value = false;
    }
    function showPreview() {
      previewing.value = true;
    }
    return {
      userInfo,
      previewing,
      showPreview,
      closePreview,
    };
  },
});
</script>


<style lang="scss" scoped>
header {
  width: 100%;
  /*height: 176px;*/
  height: 200px;
  // background-image: url("http://www.bncc.org.cn/static/m/images/bg.jpg");
  background-image: url("../assets/img/mine/bg.jpg");
  background-size: cover;
  padding-top: 25px;
  box-sizing: border-box;
  padding-left: 0 !important;
}

.avatar {
  margin: 0 auto;

  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-bottom: 10px;
  img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2.5px solid #fff;
  }
}

.user-name {
  text-align: center;
  color: #fff;
  font-size: 14px;
  p {
    margin: 5px 0;
  }
}

.menu {
  box-sizing: border-box;
  padding: 0 10px;
  overflow: hidden;
  padding-bottom: 15px;
  .menu-li {
    float: left;
    width: 25%;
    text-align: center;
    padding-top: 17.5px;
    a {
      color: #444444;
      font-size: 14px;
      display: block;
    }
    img {
      /* width: 25px; */
      height: 22px;
      margin: 0 auto;
      margin-bottom: 12.5px;
      display: block;
    }
  }
}

.menu-wapper {
  padding-bottom: 100px;
}

.border-bottom {
  border-bottom: 4px solid #f4f4f4;
}
</style>
