
  import { defineComponent, onMounted, ref, watch } from "vue";
  import { useRoute } from "vue-router";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import qs from 'qs'
  export default defineComponent({
    name: "Footer",
    setup() {
      const route = useRoute();
      const shopCarNum = ref()

      const name = ref(route.name);
      onMounted(() => {
        const guid = localStorage.getItem('guid')
        const data = qs.stringify({
          cartGUID: guid
        })
        axios.post('/M/Server/GetMyCartInfo', data)
                .then((res) => {
                  if (res.data.success) {
                    shopCarNum.value = res.data.id
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                })
                .catch((err) => {
                  console.log('err');
                });
      })

      watch(
              () => route.name,
              () => {
                name.value = route.name;
              }
      );
      return {
        name,
        shopCarNum
      };
    },
  });
