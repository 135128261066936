<template>
  <footer>
    <ul class="footer-nav">
      <li
              :class="{
          'footer-li': true,
          active: $route.meta.footActive == 'first',
        }"
      >
        <router-link :to="{ name: 'Index' }">
          <img src="@/assets/img/icon21.png" alt="" />
          <img
                  class="active-status"
                  src="@/assets/img/icon22.png"
                  alt=""
          />
          首页
        </router-link>
      </li>
      <li
              :class="{
          'footer-li': true,
          active: $route.meta.footActive == 'second',
        }"
      >
        <router-link :to="{ name: 'BnccCategory' }">
          <img src="@/assets/img/footer/category.png" alt="" />
          <img
                  class="active-status"
                  src="@/assets/img/footer/category_active.png"
                  alt=""
          />

          分类
        </router-link>
      </li>
      <li
              :class="{
          'footer-li': true,
          active: $route.meta.footActive == 'third',
        }"
      >
        <router-link :to="{ name: 'Mine' }">
          <div
                  class="mine-wapper"
                  style="
              background-image: url('@/assets/img/footer/icon29.png');
            "
          >
            <!-- background-image: url('https://www.bzwz.com/static/m/images/icon29.png'); -->
            <img src="@/assets/img/footer/icon25.png" alt="" />
            我的
          </div>
        </router-link>
      </li>
      <li
              :class="{ 'footer-li': true, active: $route.meta.footActive == 'four' }"
      >
        <!-- Course -->
        <router-link :to="{ name: 'ShopCar' }">
          <img
                  style="width: 25px;"
                  src="@/assets/img/footer/shopcar1.png"
                  alt=""
          />
          <img
                  style="width: 25px;margin-left: -13px;"
                  class="active-status"
                  src="@/assets/img/footer/shopcar2.png"
                  alt=""
          />
          <!-- <img
            src="@/assets/img/footer/spiritmap.png"
            alt=""
          /> -->
          <!-- <img
            class="active-status"
            src="@/assets/img/common/icon23-1.png"
            alt=""
          /> -->
          <div class="floatCartTotalQty">{{shopCarNum}}</div>
          <!-- src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/course-footer20201224.png -->
          <!-- src="@/assets/img/leftNav/icon13.png" -->
          <!-- src="https://www.bzwz.com/static/m/images/icon13.png" -->
          <!-- 直播间 -->
          购物车
        </router-link>
      </li>
      <li class="footer-li">
        <a
          href="https://tb.53kf.com/code/client/099be4cda651b04a92a215ad1e6edd8d8/1"
        >
          <img src="@/assets/img/icon24.png" alt="" />
          <img class="active-status" src="@/assets/img/icon24.png" alt="" />

          客服
        </a>
      </li>
    </ul>
  </footer>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref, watch } from "vue";
  import { useRoute } from "vue-router";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import qs from 'qs'
  export default defineComponent({
    name: "Footer",
    setup() {
      const route = useRoute();
      const shopCarNum = ref()

      const name = ref(route.name);
      onMounted(() => {
        const guid = localStorage.getItem('guid')
        const data = qs.stringify({
          cartGUID: guid
        })
        axios.post('/M/Server/GetMyCartInfo', data)
                .then((res) => {
                  if (res.data.success) {
                    shopCarNum.value = res.data.id
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                })
                .catch((err) => {
                  console.log('err');
                });
      })

      watch(
              () => route.name,
              () => {
                name.value = route.name;
              }
      );
      return {
        name,
        shopCarNum
      };
    },
  });
</script>

<style lang="scss" scoped>
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    border-top: 1px solid #f4f4f4;

    background-color: #fff;
    z-index: 10;
    a {
      font-size: 14px;
      color: #666;
      display: block;
    }
  }

  .footer-li {
    float: left;
    width: 20%;
    text-align: center;
    position: relative;
    .mine-wapper {
      width: 46px;
      height: 46px;
      background-color: #fff;
      border-radius: 50%;
      position: relative;
      top: -11.25px;
      margin: 0 auto;
      padding-top: 5px;
      box-sizing: border-box;
      img {
        width: 35px;
        height: 35px;
        display: block;
        margin: 0 auto;
      }
    }
    img {
      display: block;
      width: 20px;
      height: 20px;
      margin: 5px auto;
    }
    svg {
      display: block;
      width: 20px;
      height: 20px;
      margin: 5px auto;
    }

    .active-status {
      position: absolute;
      display: none;
      left: 50%;
      margin-left: -10px;
      top: 0;
      z-index: 10;
      background-color: #fff;
    }
    svg.active-status {
      position: absolute;
      display: none;
      left: 50%;
      margin-left: -10px;
      top: 0;
      z-index: 10;
      background-color: #fff;
    }
  }
  .footer-nav {
    .active {
      a {
        color: #df0024;
      }

      .active-status {
        display: block;
      }
    }
  }

  .floatCartTotalQty{
    display: block;
    position: absolute;
    right: 0.32rem;
    top: 0rem;
    background: #df0024;
    width: 0.44rem;
    height: 0.44rem;
    line-height: 0.44rem;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    z-index: 11;
  }
</style>